// Translated
// Migrated 
<template lang="pug">
.wizard
  .wizard-step(
    v-for="(stepItem, i) in steps",
    :key="i"
    :class="{ subactive: step > i + 1, active: step == i + 1 }"
  )
    span.d-none.d-md-inline {{ stepItem }}
    span.d-inline.d-md-none.text-center(data-i18n="step") {{ $t('step') }}<br>{{ i + 1 }}
</template>

<script>
export default defineNuxtComponent({
  props: {
    step: {
      type: Number,
      required: true,
    },

    steps: {
      type: Array,
      required: true,
    },
  },
})
</script>
